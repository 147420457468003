import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import PostCTA from '../BlogPostSections/PostCTA';

function Hero(props) {

  const { header, subheader, heroBgImg, curveColor, heroRef, heroCta } = props;

  let filter = 'none';

  if(heroBgImg?.contrast || heroBgImg?.brightness){
    filter = heroBgImg.contrast ?  `contrast(${heroBgImg.contrast}%)` : '';
    filter += heroBgImg.brightness ?  `brightness(${heroBgImg.brightness}%)` : '';
  }
  
  return (
    <Flex
      ref={heroRef}
      maxW="100%"
      h={
        heroCta 
          ? {base:'254px', ms:'286px', msx:'310px', md:'36.5vw', mds:'36.5vw', lg:'35.72vw', lgs:'35.75vw', xl:'35.75vw'}
          : {base:'234px', ms:'266px', msx:'290px', md:'35.5vw', mds:'35.5vw', lg:'34.72vw', lgs:'34.75vw', xl:'34.75vw'}
      }
      justifyContent="center"
      position="relative"
      id="hero-section"
      /* HERO IMAGE || NO IMAGE*/
      className={heroBgImg ? 'hero-img' : 'hero-primary-bg'}
      mb="25px"
      overflow="hidden"
      // border="1px"
      // zIndex="3"
    >
      {
      heroBgImg ? 
        heroBgImg && (
          <Flex 
            w="100vw"
            filter={filter}
          >
            <GatsbyImage image={heroBgImg.bgImage.asset.gatsbyImageData} alt={'wana hero image'} />    
          </Flex>
        )
      :
        null
    }

      {/* TEXT CONTENT */}
      <Box
        w="100%"
        h="100%"
        position="absolute"
        top={{base:'80px', ms:'95px', md:'10.5vw', mds:'11vw', lg:'11.4vw', lgs:'12.51vw', xl:'12.5vw', sl:'12.7vw', ssl:'12.5vw', xsl:'12.5vw', msl:'12.55vw', ml:'12.6vw'}}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {/* ACCENT HEADER */}
        <Text
          color={'white'}
          fontSize={{base:'16px', md:'14px', lg:'15px', xl:'16px', sl:'20px', ssl:'24px', xsl:'28px', msl:'32px', ml:'36px'}}
          fontWeight="800"
          lineHeight={{base:'20px', md:'18px', lg:'19px', xl:'20px', sl:'24px', ssl:'28px', xsl:'32px', msl:'36px', ml:'40px'}}
          letterSpacing={{base:'4.8px', md:'4.8px'}}
          textAlign="center"
          textTransform="uppercase"
          // style={{textShadow:'3px 2px 2px #858585'}}
        >
          {subheader}
        </Text>

        {/* MAIN HEADER */}
        <Heading
          color={'white'}
          fontFamily="Outfit"
          fontSize={{base:'33px', msx:'49px', md:'53px', lg:'57px', lgs:'61px', xl:'65px', sl:'70px', ssl:'75px', xsl:'80px', msl:'85px', ml:'90px'}}
          fontWeight="900"
          lineHeight={{base:'45px', msx:'73px', md:'77px', lg:'81px', lgs:'85px', xl:'89px', sl:'94px', ssl:'99px', xsl:'104px', msl:'109px', ml:'114px'}}
          letterSpacing={{base:'0.55px', md:'1.08px'}}
          textAlign="center"
          textTransform="uppercase"
          // style={{textShadow:'3px 2px 2px #858585'}}
        >
          {header}
        </Heading>

        {/* HERO CTA */}
        {heroCta && (
          <PostCTA 
            text={heroCta._rawTextContent}
            link={heroCta.externalLink || heroCta.internallLink?.route || '/'}
            color={heroCta.textColor.color}
            bgColor={heroCta.bgColor.color}
            inHero
          />
        )}
      </Box>

      {/* SVG BOTTOM IMAGE */}
      <Box
        w="100%"
        h={{base:'30%', md:'25%', lg:'30%', xl:'30%', xxl:'31%', sl:'32%', ssl:'40%'}}
        position="absolute"
        bottom={{base:'-8px', ms:'-10px'}}
        // border="1px"
      >
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill={curveColor || 'white'}
            d="M-0.27,73.53 C192.15,3.48 325.90,143.59 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg> 
      </Box>

    </Flex>
  );
}

export default Hero;
