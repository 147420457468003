import * as React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import OneColumnText from '../OneColumnText';
import LinkWrapper from '../../../components/LinkWrapper';

function PostCTA(props) {
  const { text, link, color, bgColor, inHero } = props;

  const [hover, setHover] = React.useState(false);

  const fontSize = !inHero ? {base:'16px', md:'16px', mds:'16px', lg:'16px', lgs:'17px', xl:'18px'} : {base:'12px', md:'14px', mds:'15px', lg:'16px', lgs:'17px', xl:'18px', xxl:'19px', sl:'20px', ssl:'21px', xsl:'22px', msl:'23px', ml:'24px'};
  /* STYLES */
  const oneColumnTextStyles = {
    fontColor: !hover ? color : bgColor,
    fontSizes: {
      'small': fontSize,
      'normal': fontSize,
      'h4': fontSize,
      'h3': fontSize,
      'h2': fontSize,
      'h1': fontSize,
    },
    fontWeights: {
      'normal': '400',
      'strong': '600',
    },
    lineHeights: {
      'small': {base:'1', md:'1'},
      'normal': {base:'1', md:'1'},
      'h4': {base:'1', md:'1'},
      'h3': {base:'1', md:'1'},
      'h2': {base:'1', md:'1'},
      'h1': {base:'1', md:'1'},
    },
    letterSpacings: {
      'small': {base:'-0.14px', md:'-0.14px'},
      'normal': {base:'-0.14px', md:'-0.14px'},
      'h4': {base:'-0.14px', md:'-0.14px'},
      'h3': {base:'-0.14px', md:'-0.14px'},
      'h2': {base:'-0.14px', md:'-0.14px'},
      'h1': {base:'-0.14px', md:'-0.14px'},
    },
    textAlign: {base:'center', md:'center'},
    mbSection: {base:'0px', md:'0px'},
    mbContainers: {base:'0px', md:'0px'},
    hover: {borderColor:'primary'},
    linkFontStyle: 'italic',
    listsLeftMargin: {base:'30px', md:'60px'},
    listsRightMargin: {base:'20px', mds:'10px'},
    listItemBottomMargin: {base:'10px', mds:'10px'},
  };
  return (
    <LinkWrapper href={link}>
      <Flex
        w={inHero ? {base:'max-content', ms:'max-content'} : {base:'95%', ms:'80%', msx:'450px'}}
        justifyContent="center"
        alignItems="center"
        color={!hover ? color : bgColor}
        bgColor={!hover ? bgColor : (color === '#F1F0FA' || color === '#FFFFFF') ? 'blue' : color}
        borderRadius={{base:'42px', xl:'2.92vw'}}
        p={
          inHero
            ? {base:'6px 8px', ms:'1vw 1.95vw', msx:'1vw 1.95vw', md:'1vw 1.95vw', mds:'1vw 1.95vw', lg:'1vw 1.95vw', lgs:'1vw 1.95vw', xl:'1vw 1.95vw'}
            : {base:'1.95vw', ssm:'1.95vw', ms:'1.95vw', msx:'1.95vw', md:'1.95vw', mds:'1.95vw', lg:'1.95vw', lgs:'1.95vw', xl:'28px'}
        }
        mx="auto"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        border="1px"
        borderColor={(color === '#F1F0FA' || color === '#FFFFFF') ? 'primary' : bgColor}
      >
        <OneColumnText 
          _rawOneColumnText={text} 
          styles={oneColumnTextStyles}
          extra={'cta'}
        />
      </Flex>
    </LinkWrapper>
  );
}

export default PostCTA;
