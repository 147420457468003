import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image'; 
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import LinkWrapper from '../../components/LinkWrapper';

const supportedOrganizationsLinks = [
  {
    name:'Audio Information Network of Colorado',
    link:'https://aincolorado.org/',
  },
  {
    name:'Animal Assisted Therapy Programs of Colorado',
    link:'https://aincolorado.org/',
  },
  {
    name:'Asian Americans Advancing Justice',
    link:'https://aincolorado.org/',
  },
  {
    name:'Bridge House',
    link:'https://aincolorado.org/',
  },
  {
    name:'Color of Cannabis',
    link:'https://aincolorado.org/',
  },
  {
    name:'Colorado Healing Fund',
    link:'https://aincolorado.org/',
  },
  {
    name:'Community Food Share',
    link:'https://aincolorado.org/',
  },
  {
    name:'Detroit Dog Rescue',
    link:'https://aincolorado.org/',
  },
  {
    name:'Eco-Cycle',
    link:'https://aincolorado.org/',
  },
  {
    name:'Heidelberg Project',
    link:'https://aincolorado.org/',
  },
  {
    name:'Project Helping',
    link:'https://aincolorado.org/',
  },
  {
    name:'Last Prisoner Project',
    link:'https://aincolorado.org/',
  },
  {
    name:'Leaf411',
    link:'https://aincolorado.org/',
  },
  {
    name:'Museo De Las Americas',
    link:'https://aincolorado.org/',
  },
  {
    name:'Oregon Humane Society',
    link:'https://aincolorado.org/',
  },
  {
    name:'Out Boulder County',
    link:'https://aincolorado.org/',
  },
  {
    name:'Safehouse Progressive Alliance Nonviolence',
    link:'https://aincolorado.org/',
  },
  {
    name:'Street Wise Arts',
    link:'https://aincolorado.org/',
  },
  {
    name:'There With Care',
    link:'https://aincolorado.org/',
  },
  {
    name:'We Don’t Waste',
    link:'https://aincolorado.org/',
  },
  {
    name:'Wildland Firefighter Foundation',
    link:'https://aincolorado.org/',
  },
];

function WanaSupporterOf(props) {
  let supporterTitle = ''
  const { data, charityHeading } = props;

  charityHeading ? supporterTitle = "Charitable Support." : 
                   supporterTitle = data.title
  
  /* SET THE LINKS LIST MAX HEIGHT */
  const [linksListMaxHeight, setLinksListMaxHeight] = React.useState(null);

  React.useEffect(() => {
    const designMaxLinksListItems = 18; // 21 items set on the design.
    const totalLinksListItems = data.organizationsList.length;
    const linksDiff = totalLinksListItems - designMaxLinksListItems;

    const supportedOrgsMaxHight = {
      base:'auto', // 40 extra pixels per new item added to the list `${900 + (linksDiff * 40)}px`.
      ms:`${750 + (linksDiff * 37)}px`,
      md:`${400 + (linksDiff * 29)}px`,
      lg:`${350 + (linksDiff * 29)}px`,
      xl:`${335 + (linksDiff * 21)}px`,
    };
    
    setLinksListMaxHeight(supportedOrgsMaxHight);
  }, []);

  return (
    <Box
      w="100%"
      h="auto"
      mx="auto"
      mt={{base:'5vw', md:'6.95vw', xl:'100px'}}
      display="flex"
      flexDirection="column"
      position="relative"
      pt={{base:'0'}}
      pb={{base:'100px', md:'150px'}}
      bgColor="grayBG"
    >
      {/* SVG TOP IMAGE */}
      <SvgCurve top />

      {/* WANA SUPPORTER OF */}
      <Flex
        maxW={{base:'90%', ms:'80%', md:'90%', xl:'1200px'}}
        mx="auto"
        direction="column"
        style={{zIndex:1}}
      >
        {/* TITLE */}
        {charityHeading ? 
          <Text
            color="primary"
            fontSize={{base:'35px', md:'40px', lg:'40px', xl:'50px'}}
            fontWeight="700"
            letterSpacing={{base:'0px', md:'0px'}}
            lineHeight="1"
            textAlign="center"
            pt={{base:'82px', md:'140px', lg:'119px', xl:'124px'}}
            pb={{base:'40px', md:'40px', lg:'35px', xl:'28px'}}
          >{supporterTitle}
          </Text> :
            <Text
            color="primary"
            fontSize={{base:'20px', md:'20px'}}
            fontWeight="800"
            letterSpacing={{base:'6px', md:'6px'}}
            lineHeight="1"
            textAlign="center"
            textTransform="uppercase"
            pt={{base:'112px', md:'140px', lg:'175px', xl:'219px'}}
            pb={{base:'56px', md:'70px', lg:'87px', xl:'108px'}}
          >{supporterTitle}</Text>
        }
        {charityHeading ? 
        <span className='charitable-text'>
          {data?.text}
        </span> : null}
        {/* TOP IMAGES */}
        <Flex
          w="100%"
          maxH={{base:'auto', md:'83px'}}
          direction={{base:'column', md:'row'}}
          alignItems="center"
          mb={{base:'5px', md:'91px', lg:'101px', xl:'112px'}}
        >
          {data.topOrganizations.map((item, indx) => {
            return (
              <LinkWrapper key={indx} href={item.link}>
                <Flex
                  maxW={{base:'300px', md:'290px'}}
                  maxH={{base:'auto', md:'83px'}}
                  h="100%"
                  _last={{mr:'0'}}
                  ml={indx === 0 ? {base:'0'} : {base:'0', md:'35px'}}
                  mr={{base:'0', md:'35px'}}
                  mb={{base:'40px', md:'0'}}
                >
                  <GatsbyImage 
                    image={item.img.asset.gatsbyImageData}
                    alt={'wana supported organization image'}
                    objectFit="contain"
                  />
                </Flex>
              </LinkWrapper>
            );
          })}
        </Flex>

        {/* LINKS LIST */}
        {charityHeading ? linksListMaxHeight && (
          <Flex
            w={{base:'100%', md:'100%', xl:'96%'}}
            maxH={linksListMaxHeight}
            mx="auto"
            direction="column"
            alignItems="center"
            flexWrap="wrap"
            position="relative"
            pb={{base:'0px', ms:'50px', mds:'70px', md:'0px', lg:'0px'}}
          >
            {data.organizationsList.map((item, indx) => {
              return (
                <LinkWrapper 
                  key={indx} 
                  href={item.link} 
                  w={{base:'80%', ms:'45%', md:'220px', lg:'210px', xl:'230px'}}
                >
                  <Text
                    color="#757196"
                    fontSize={{base:'15px', md:'15px'}}
                    fontWeight="800"
                    letterSpacing={{base:'0', md:'0'}}
                    lineHeight={{base:'25px', md:'25px'}}
                    // textTransform="capitalize"
                    px={{base:'21px', md:'21px'}}
                    mb={{base:'21px', md:'21px'}}
                    borderLeft="2px"
                    borderColor="white"
                    textAlign={{base:'left'}}
                  >
                    {item.name}
                  </Text>
                </LinkWrapper>
              );
            })}

          </Flex>
        ):
        linksListMaxHeight && (
          <Flex
            w={{base:'100%', md:'100%', xl:'96%'}}
            maxH={linksListMaxHeight}
            mx="auto"
            direction="column"
            alignItems="center"
            flexWrap="wrap"
            position="relative"
          >
            {data.organizationsList.map((item, indx) => {
              return (
                <LinkWrapper 
                  key={indx} 
                  href={item.link} 
                  w={{base:'80%', ms:'45%', md:'220px', lg:'210px', xl:'230px'}}
                >
                  <Text
                    color="#757196"
                    fontSize={{base:'15px', md:'15px'}}
                    fontWeight="800"
                    letterSpacing={{base:'0', md:'0'}}
                    lineHeight={{base:'25px', md:'25px'}}
                    // textTransform="capitalize"
                    px={{base:'21px', md:'21px'}}
                    mb={{base:'21px', md:'21px'}}
                    borderLeft="2px"
                    borderColor="white"
                    textAlign={{base:'left'}}
                  >
                    {item.name}
                  </Text>
                </LinkWrapper>
              );
            })}

          </Flex>
        )}
      </Flex>

      {/* SVG BOTTOM IMAGE */}
      <SvgCurve top={false} />
    </Box>
  );
}

/* SVG CURVE */
const SvgCurve = ({ top }) => {
  const position = top ? {top:'-1px'} : {bottom:'-1px'};

  return (
    <Box
      w="100%"
      h={top ? {base:'3%', md:'12%'} : {base:'5%', md:'10%'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M0.28,84.38 C149.99,150.00 281.88,-99.16 500.27,79.45 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg> 
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M0.00,49.98 C149.99,150.00 330.98,145.56 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg> 
      )}
    </Box>
  );
};

export default WanaSupporterOf;
