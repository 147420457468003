import * as React from 'react';
import { graphql} from 'gatsby';
import { Box, Text, Flex } from '@chakra-ui/react';
import SeoComponent from '../components/SeoComponent';
import Loading from '../components/Loading';

/* SECTIONS */
import Hero from '../sections/Hero';
// import AboutDetails from '../sections/AboutDetails';
import NewAboutDetails from '../sections/NewAboutDetails';
import WanaSupporterOf from '../sections/WanaSupporterOf';

type objectType = { [x: string]: any };

function AboutPage(props) {
  const { data } = props;
  const [sections, setSections] = React.useState<objectType | null>(null);
  const seoImg = data.sanityPage.seoImage?.image || (sections as any)?.hero?.heroImg?.bgImage;

  React.useEffect(() => {
    const sectionsObj = {} as objectType;

    data.sanityPage.sections.map((section, indx) => {
      sectionsObj[section._type] = section;
    });
    
    setSections(sectionsObj);
  }, []);
  
  return (
    <Box>
      <SeoComponent 
        title={data.sanityPage.seoTitle} 
        description={data.sanityPage.seoDescription} 
        imageUrl={seoImg?.asset?.url}
      />

      {sections && (
        <Box>
          {/* HERO */}
          <Hero
            header={sections.hero.mainHeader.headerText}
            subheader={sections.hero.accentHeader.headerText}
            heroBgImg={sections.hero.heroImg}
          />

          {/* ABOUT DETAILS */}
          <NewAboutDetails data={sections.aboutDetails} isAboutPage />

          {/* WANA SUPPORTER OF */}
          <WanaSupporterOf data={sections.wanaSupporterOf} />
          
        </Box>
      )}

      {!sections && (
        <Flex
          w="100%"
          minH="100vh"
          mx="auto"
          justifyContent="center"
          alignItems="center"
        >
          <Loading />
        </Flex>
      )}
    </Box>
  );
}

export const query = graphql`
  query {
    sanityPage(slug: { current: { eq: "about" } }) {
      _id
      slug {
        current
      }
      seoTitle
      seoDescription
      seoImage {
        image {
          asset {
            url
          }
        }
      }
      sections {
        ... on SanityHero {
          _key
          _type
          accentHeader {
            headerText
          }
          mainHeader {
            headerText
          }
          heroImg {
            bgImage {
              asset {
                url
                gatsbyImageData(placeholder: NONE, width: 6000)
              }
            }
            brightness
            contrast
          }
        }
        ... on SanityAboutDetails {
          _key
          _type
          sectionHeader
          aboutTitle
          aboutDetailsItems {
            itemTitle
            itemDetails
            _rawItemDetailsBlock
            itemImage {
              asset {
                gatsbyImageData(width: 606, height: 437,placeholder: NONE)
              }
            }
            itemVideoUrl
          }
        }
        ... on SanityWanaSupporterOf {
          _key
          _type
          title
          topOrganizations {
            name
            link
            img {
              asset {
                gatsbyImageData
              }
            }
          }
          organizationsList {
            name
            link
          }
        }
      }
    }
    allSanityProductCategory(sort: { fields: _createdAt }) {
      nodes {
        name
        slug {
          current
        }
        subcategories {
          name
          slug {
            current
          }
          category {
            slug {
              current
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
