import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image'; 
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import { AiFillPlayCircle } from 'react-icons/ai';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import TextStrokeHeader from '../../components/TextStrokeHeader';
import OneColumnText from '../BlogPostSections/OneColumnText';

/* MODAL SETUP */
const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    padding: 0,
    border: 'none',
    borderRadius: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#___gatsby');

/* RICH TEST STYLES */
const oneColumnTextStyles = {
  fontSizes: {
    'normal': {base:'15px', md:'15px'},
    'h4': {base:'17px', md:'17px'},
    'h3': {base:'19px', md:'19px'},
    'h2': {base:'21px', md:'21px'},
    'h1': {base:'23px', md:'23px'},
  },
  fontWeights: {
    'normal': '400',
    'strong': '600',
  },
  lineHeights: {
    'normal': {base:'25px', md:'25px'},
    'h4': {base:'27px', md:'27px'},
    'h3': {base:'29px', md:'29px'},
    'h2': {base:'31px', md:'31px'},
    'h1': {base:'33px', md:'33px'},
  },
  letterSpacings: {
    'normal': {base:'0px', md:'0px'},
    'h4': {base:'0px', md:'0px'},
    'h3': {base:'0px', md:'0px'},
    'h2': {base:'0px', md:'0px'},
    'h1': {base:'0px', md:'opx'},
  },
  textAlign: {base:'left', md:'left'},
  mbSection: {base:'0px', md:'0px'},
  mbContainers: {base:'20px', md:'20px'},
  hover: {borderColor:'primary'},
  linkFontStyle: 'italic',
  listsLeftMargin: {base:'30px', md:'60px'},
};

function AboutDetails(props) {
  const { data, isAboutPage } = props;
  
  /* MODAL CONTROL */
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState(null);

  const openModal = (item) => {
    setVideoURL(item.itemVideoUrl);
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      {/* SECTION */}
      <Box
        maxW="100%"
        h="auto"
        mx="auto"
        mt="5px"
        display="flex"
        flexDirection="column"
        position="relative"
        pt={{base:'0'}}
        pb={{base:'70px', md:'0'}}
        bgColor="grayBG"
      >
        {/* SVG TOP IMAGE */}
        <SvgCurve top />

        {/* ABOUT DETAILS */}
        <Flex
          maxW="1440px"
          mx="auto"
          direction="column"
        >
          {/* SECTION HEADER */}
          <Box
            style={{zIndex:2}}
          >
            <TextStrokeHeader 
              text={data.sectionHeader} 
              fontSize={{base:'12.92vw', ms:'10.5vw', msx:'10vw', md:'9.5vw', lg:'9.5vw', lgs:'11.5vw', xl:'186px'}}
              letterSpacing={{base:'-1.94px', md:'-1.5px'}}
              opacity={'0.1'}
              textStroke='1px'
              lineHeight={{base:'0.8', md:'0.8', lg:'0.9'}}
              noOfLines={{base:1}}
            />
          </Box>
        </Flex>

        {/* TITLE */}
        <Flex
          display={isAboutPage ? {base:'block', lgs:'none'} : 'none'}
          w={{base:'100%', ssm:'95%', ms:'95%'}}
          mt={{base:'-1vw', ms:'-1vw', msx:'-1vw', md:'-1vw', mds:'-1.3vw', lg:'-1.5vw'}}
          mb={{base:'35px', ms:'35px', msx:'35px', mds:'0'}}
          mx="auto"
          style={{zIndex:2}}
        >
          <Text
            color="primary"
            fontSize={{base:'35px', ms:'37px', msx:'39px', md:'45px', mds:'45px', lg:'45px'}}
            fontWeight="800"
            letterSpacing={{base:'-0.5px', md:'-0.5px'}}
            lineHeight={{base:'1', md:'1'}}
            textAlign={{base:'center'}}
          >
            {data.aboutTitle}
          </Text>
        </Flex>

        {/* CONTENT */}
        <Flex 
          maxW="1440px"
          w={{base:'87%', msx:'80%', md:'80%', mds:'100%'}}
          direction="column"
          mx="auto"
          // border="1px"
          // borderColor="#000"
        >
          {data.aboutDetailsItems.map((item, indx) => {
            return (
              <AboutItem 
                key={indx} 
                title={data.aboutTitle}
                item={item} 
                index={indx} 
                openModal={() => openModal(item)} 
                isAboutPage={isAboutPage}
              />
            );
          })}
        </Flex>

        {/* SVG BOTTOM IMAGE */}
        <SvgCurve top={false} />
      </Box>

      {/* MODAL */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel='Example Modal'>
        <Box className='player-wrapper'>
          <ReactPlayer className='react-player' width='100%' height='100%' playing={modalIsOpen} auto url={videoURL} controls={true}/>
        </Box>
      </Modal>
    </>
  );
}

/* SVG CURVE */
const SvgCurve = ({ top }) => {
  const position = top ? {top:'0'} : {bottom:'0'};

  return (
    <Box
      w="100%"
      h={top ? {base:'8%', md:'45%', lg:'44%', xl:'45%'} : {base:'15%', md:'35%', lg:'45%'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            // d="M0.28,72.55 C187.63,87.34 271.16,16.30 501.97,75.50 L500.00,0.00 L0.00,0.00 Z"
            // d="M0.28,85.38 C149.26,115.97 278.49,34.05 501.41,86.36 L500.00,0.00 L0.00,0.00 Z"
            d="M-2.54,92.28 C149.26,112.02 278.49,34.05 501.41,86.36 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg> 
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M-0.27,60.70 C80.41,92.28 420.71,91.30 500.27,50.83 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg> 
      )}
    </Box>
  );
};

/* ABOUT ITEM */
const AboutItem = ({ title, item, index, openModal, isAboutPage }) => {
  const isPar = index % 2 === 0;
  const isTwoCols = item?.itemImage?.asset?.gatsbyImageData;

  return(
    <Flex 
      alignItems="center"
      direction={{base:'column-reverse', mds:!isPar ? 'row-reverse' : 'row'}}
      justifyContent={!isPar ? 'space-between' : 'normal'}
      mt={(index === 0 && isAboutPage) ? {lg:'0', lgs: '-6.5vw', xl:'-90px'} : '0'}
      mb={!isPar ? {base:'10vw', md:'8vw', mds:'4.5vw', lg:'4.5vw', lgs:'8.6vw', xl:'120px'} : {base:'1vw', md:'1vw', lg:'1vw', lgs:'3.47vw', xl: '50px'}}
      // border="1px"
      style={{zIndex:3}}
    >
      {/* TEXT SECTION */}
      <Flex
        w={{base:'100%', mds: isTwoCols ? (!isPar ? '50%' : '60%') : '100%', lg: isTwoCols ? '50%' : '100%'}}
        direction="column"
        p={
          isAboutPage 
          ? {base:'35px 2.5% 47px 2.5%', md:'44px 5% 59px 5%', mds:'30px 8.82vw 30px 8.68vw', lg:'40px 8.82vw 40px 8.68vw', lgs:'55px 8.82vw 73px 8.68vw', xl:'69px 127px 91px 125px'}
          : {base:'50px 2.5% 47px 2.5%', md:'40px 5% 59px 5%', mds:'40px 8.82vw 30px 8.68vw', lg:'40px 8.82vw 40px 8.68vw', lgs:'55px 8.82vw 73px 8.68vw', xl:'69px 127px 91px 125px'}
        }
        bgColor={!isPar ? 'primary' : 'transparent'}
        style={{zIndex:2}}
        mt={!isPar ? {lg:'0', lgs:'-10.42vw', xl:'-150px'} : '0'}
        // border="1px"
      >
        {/* TITLE */}
        {index === 0 && title && (
          <Text
            w="100%"
            display={isAboutPage ? {base:'none', lgs:'block'} : 'block'}
            color="primary"
            fontSize={{base:'45px', md:'49px', mds:'53px', lg:'57px', lgs:'61px', xl:'65px'}}
            fontWeight="800"
            letterSpacing={{base:'-0.5px', md:'-0.5px'}}
            lineHeight={{base:'1', md:'1'}}
            textAlign={isAboutPage ? {base:'center', md:'left'} : 'left'}
            mb={isAboutPage ? {lg:'3vw', lgs:'4.3vw', xl:'55px'} : { base: '24px', lg: '3vw', lgs:'4.3vw', xl:'55px' }}
            // border="1px"
          >
            {title}
          </Text>
        )}

        {/* TOP TEXT */}
        {item?.itemTitle && (
          <Text
            color={!isPar ? 'white' : 'primary'}
            fontSize={{base:'29px', ms:'30px', msx:'31px', md:'32px', mds:'33px', lg:'34px', lgs:'35px', xl:'36px'}}
            fontWeight="800"
            letterSpacing={{base:'-0.28px', md:'-0.28px'}}
            lineHeight={{base:'1.5', md:'1.5'}}
            mb={{base:'20px', md:'10px', lg:'20px'}}
          >
            {item.itemTitle}
          </Text>
        )}

        {/* BOTTOM TEXT */}
        {item._rawItemDetailsBlock && (
          <Box
            pl={{base:'0', mds:'2.43vw', xl:'35px'}}
            // border="1px"
          >
            <OneColumnText 
              _rawOneColumnText={item._rawItemDetailsBlock} 
              styles={{...oneColumnTextStyles, fontColor: !isPar ? 'white' : 'primary'}}
            />
          </Box>
        )}
      </Flex>

      {/* MEDIA */}
      {isTwoCols && (
        <Flex
          w={{base:'95%', md:'50vw', mds:'47vw', lg:'42vw', lgs:'42vw', xl:'606px'}}
          h={{base:'auto', md:'auto', mds:'32.4vw', lg:'30.35vw', lgs:'30.35vw', xl:'437px'}}
          mx={{base:'auto', mds:'0'}}
          position="relative"
          style={{zIndex:1}}
          mt={(index > 0 && isPar) ? {lg:'0', lgs:'-10.42vw', xl:'-150px'} : '0'}
          mb={!isPar ? {base:'60px', msx:'60px', md:'70px', mds:'20px', lg:'0'} : {base:'5px', msx:'5px', mds:'20px', lg:'0'}}
          // bgColor="blue"
        >
          <GatsbyImage 
            image={item.itemImage.asset.gatsbyImageData}
            alt={'about item image'}
          />

          {/* PLAY BUTTON IF MEDIA IS A VIDEO */}
          {item?.itemVideoUrl && (
            <Box
              w="100%"
              h="100%"
              position="absolute"
              top="0"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {/* ICON */}
              <Box
                w="auto"
                h="auto"
                borderRadius="full"
                cursor='pointer'
                style={{zIndex:2}}
                onClick={openModal}
              >
                <AiFillPlayCircle style={{ fontSize: '92px', color: '#272158' }} />
              </Box>

              {/* ICON WHITE BACKGROUND */}
              <Box
                w="46px"
                h="46px"
                bgColor="white"
                borderRadius="full"
                position="absolute"
              />
            </Box>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default AboutDetails;
