import * as React from 'react';
import { Box, Text, Heading } from '@chakra-ui/react';

const isBrowser = typeof window !== "undefined";

function TextStrokeHeader(props) {
  const { text, textColor, fontSize, lineHeight, letterSpacing, strokeColor, opacity, mb, mt, children, textStroke, fontWeight, noOfLines} = props;
  
  let isSafari = null;
  let isFirefox = null;
  let isOpera = null;

  if (isBrowser) {
    // @ts-ignore
      isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    // @ts-ignore
      isFirefox = typeof InstallTrigger !== 'undefined';
    // @ts-ignore
      isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  }

    return (
    <Box 
      h="max-content"
      style={{zIndex:1}} 
    >
      <Heading
        mb={mb || { base: '-24px', md: '-20px', lg: '-38px', xl: '-52px'}}
        mt={mt || { base: '0'}}
        color={textColor|| 'white'}
        fontFamily="Nunito Sans"
        fontSize={fontSize || { base: '66px', ms: '80px', md: '105px', lg: '152px', xl: '190px'}}
        fontWeight="900"
        letterSpacing={letterSpacing || { base: '-1.94px', md: '-0.5px' }}
        lineHeight={lineHeight || { base: '70px', lg: '132.8px', xl: '166px' }}
        textAlign='center'
        textTransform='uppercase'
        // opacity={opacity || '0.2'}
        noOfLines={noOfLines || {base:2}}
        className={isSafari || isOpera ? "use-text-stroke-safari" : isFirefox ? 'use-text-stroke-moz' : 'use-text-stroke-chrome'}
      >
        {text}
      </Heading>
      {children}
    </Box>
  );
}

export default TextStrokeHeader;
