import * as React from 'react';

const Loading = (props) => {

  return (
    <div className="loading"></div>
  );
}

export default Loading;
